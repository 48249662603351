<template>
  <div class="vld-parent cyb-login">
    <login-form @sendSubmit="handleSubmit" formName="registerForm">
      <template #form>
        <Card class="p-fluid">
          <template #header>
            <h1 class="active">{{ $t('register.title') }}</h1>
            <i
              >({{ $t('login.already_user') }}
              <router-link to="./Login" tag="a">{{ $t('login.login_title') }}</router-link
              >)</i
            >
          </template>
          <template #title></template>
          <template #content>
            <!--First Name-->
            <div class="p-fluid p-grid">
              <div class="p-col-6 p-pt-0 p-pb-1">
                <div class="p-field">
                  <InputText
                    :placeholder="$t('register.first_name')"
                    v-model="user.firstName"
                    type="text"
                    name="firstname"
                    autofocus
                  />
                </div>
                <field-error :value="v$.user.firstName" />
              </div>
              <!--LastName -->
              <div class="p-col-6 p-pt-0 p-pb-1">
                <div class="p-field">
                  <InputText
                    v-model="user.lastName"
                    type="text"
                    name="lastName"
                    :placeholder="$t('register.last_name')"
                  />
                </div>
                <field-error :value="v$.user.lastName" />
              </div>
            </div>
            <!--Company Name-->
            <div class="p-fluid p-grid">
              <div class="p-col p-pt-0 p-pb-1">
                <div class="p-field">
                  <InputText
                    :placeholder="$t('register.companyName')"
                    v-model="user.companyName"
                    type="text"
                    name="companyName"
                    autofocus
                  />
                </div>
                <field-error :value="v$.user.companyName" />
              </div>
            </div>
            <!--Email -->
            <div class="p-fluid p-grid">
              <div class="p-col p-pt-0 p-pb-1">
                <div class="p-field">
                  <InputText
                    v-model="user.email"
                    type="email"
                    name="email"
                    :placeholder="$t('common.email')"
                  />
                  <field-error :value="v$.user.email" />
                </div>
              </div>
            </div>
            <!--Email -->
            <div class="p-fluid p-grid">
              <div class="p-col p-pt-0 p-pb-1">
                <div class="p-field">
                  <InputText
                    :placeholder="$t('register.email_confirmation')"
                    v-model="user.emailConfirmation"
                    type="email"
                    name="emailConfirmation"
                  />
                  <field-error :value="v$.user.emailConfirmation" />
                </div>
              </div>
            </div>
            <!--Password -->
            <div class="p-fluid p-grid">
              <div class="p-col p-pt-0 p-pb-1">
                <div class="p-field">
                  <PasswordComplexity
                    :placeholder="$t('common.password')"
                    v-model="user.password"
                    name="password"
                    toggleMask
                    autocomplete="password"
                  >
                  </PasswordComplexity>
                  <field-error :value="v$.user.password" />
                </div>
              </div>
            </div>
            <!--Password -->
            <div class="p-fluid p-grid">
              <div class="p-col p-pt-0 p-pb-1">
                <div class="p-field">
                  <Password
                    :placeholder="$t('register.confirm_password')"
                    v-model="user.passwordConfirmation"
                    name="passwordConfirmation"
                    toggleMask
                    :feedback="false"
                    autocomplete="password-confirm"
                  />
                  <field-error :value="v$.user.passwordConfirmation" />
                </div>
              </div>
            </div>
            <!--Phone -->
            <div class="p-fluid p-grid">
              <div class="p-col p-pt-0 p-pb-1">
                <div class="p-field">
                  <InputText
                    v-model="user.phone"
                    type="text"
                    name="phone"
                    :placeholder="$t('register.phone')"
                  />
                  <field-error :value="v$.user.phone" />
                </div>
              </div>
            </div>
            <!--Country -->
            <div class="p-fluid p-grid">
              <div class="p-col p-pt-0 p-pb-1">
                <div class="p-field">
                  <Dropdown
                    id="country"
                    v-model="user.country"
                    :options="countries"
                    optionLabel="name"
                    optionValue="name"
                    :filter="true"
                    :placeholder="$t('register.country_placeholder')"
                  />
                  <field-error :value="v$.user.country" />
                </div>
              </div>
            </div>
            <!--State -->
            <div class="p-fluid p-grid">
              <div class="p-col p-pt-0 p-pb-1">
                <div class="p-field">
                  <InputText
                    v-model="user.state"
                    type="text"
                    name="state"
                    :placeholder="$t('register.state')"
                  />
                  <field-error :value="v$.user.state" />
                </div>
              </div>
            </div>
            <!--Interests -->
            <div class="p-fluid p-grid">
              <div class="p-col p-pt-0 p-pb-1">
                <div class="p-field">
                  <Dropdown
                    id="interests"
                    v-model="user.interests"
                    name="interests"
                    :placeholder="$t('register.interest_placeholder')"
                    :options="interests"
                    optionLabel="name"
                    optionValue="name"
                  />
                  <field-error :value="v$.user.interests" />
                </div>
              </div>
            </div>
            <div class="p-fluid p-grid">
              <div class="p-col p-pt-0 p-pb-1">
                <div class="p-field-checkbox">
                  <Checkbox
                    id="chkAcceptConditions"
                    v-model="acceptTheConditions"
                    :binary="true"
                  ></Checkbox>
                  <label for="chkAcceptConditions"
                    >{{ $t('login.registering_disclosure_start') }}
                    <a
                      href="https://files.cybelesoft.com/docs/license-support-and-warranty-terms.pdf"
                      target="_blank"
                      >{{ $t('login.registering_terms_and_conditions') }}</a
                    >
                    {{ $t('login.registering_disclosure_end') }}</label
                  >
                </div>
              </div>
            </div>
            <div class="recaptchaContainer">
                <vue-recaptcha v-if="showRecaptcha" :siteKey="reCaptchaSiteKey" theme="light" :size="(compactCaptcha) ? 'compact' : 'normal'" @verify="reCaptchaVerified" @expire="reCaptchaExpired" @fail="reCaptchaFailed" ref="vueRecaptcha"></vue-recaptcha>
            </div>
          </template>
          <template #footer>
            <field-error class="form-errmsg p-mb-2 text-center" id="msgerror" :value="errorMessage"/>
            <Button type="submit" :label="$t('register.register_button')" :disabled="(inProcess || !confirmed)" />
          </template>
        </Card>
      </template>
    </login-form>
  </div>

  <CommonDialog ref="dialog" style="max-width: 35rem"></CommonDialog>

</template>

<script>
import { authService } from '../services/auth.service';
import { useVuelidate } from '@vuelidate/core';
import { email, required, sameAs } from '@vuelidate/validators';
import CountriesHelper from '../helpers/CountriesHelper';
import { ErrorHelper } from '../helpers/ErrorHelper';
import LoginForm from '../components/LoginForm';
import PasswordComplexity from '../components/PasswordComplexity.vue';
import FieldError from '../components/FieldError.vue';
import vueRecaptcha from 'vue3-recaptcha2';
import { apiConfig } from '../config/backend-api';
import CommonDialog from '../components/CommonDialog.vue';

export default {
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      showRecaptcha: false,
      reCaptchaSiteKey: '',
      errorMessage: '',
      headerMessage: '',
      countries: null,
      countriesHelper: null,
      user: {
        companyName: '',
        firstName: '',
        lastName: '',
        email: '',
        emailConfirmation: '',
        password: '',
        passwordConfirmation: '',
        phone: '',
        country: '',
        state: '',
        interests: '',
        reCaptchaToken: '',
      },
      acceptTheConditions: false,
      inProcess: false
    };
  },
  computed: {
    loginWelcome() {
      return ErrorHelper.getLinesFrom(this.$t('login.welcomeMessage'), '\n');
    },
    interests() {
      let collection = [];
      let values = this.$t('register.interestsOptions');
      if (values) {
        values.split(';').forEach((value) => collection.push({ name: value }));
      }
      return collection;
    },
    confirmed() {
      return (this.user.reCaptchaToken !== '' && this.acceptTheConditions);
    },
    compactCaptcha() {
      return window.outerWidth < 500;
    }
  },
  validations() {
    return {
      user: {
        companyName: { required },
        firstName: { required },
        lastName: { required },
        email: { required, email },
        emailConfirmation: { required, sameAsEmail: sameAs(this.user.email) },
        password: { required },
        passwordConfirmation: { required, sameAsPassword: sameAs(this.user.password) },
        phone: { required },
        country: { required },
        state: { required },
        interests: { required },
      },
      acceptTheConditions: { required },
    };
  },
  created() {
    this.countriesHelper = new CountriesHelper();
  },
  mounted() {
    this.reCaptchaSiteKey = apiConfig.reCaptchaSiteKey;
    this.showRecaptcha = !!this.reCaptchaSiteKey;
    this.countriesHelper.getCountries().then((data) => (this.countries = data));
  },
  components: {
    LoginForm,
    PasswordComplexity,
    FieldError,
    vueRecaptcha,
    CommonDialog
  },
  methods: {
    handleSubmit() {
      this.errorMessage = '';
      this.v$.$touch();
      if (this.v$.$invalid) {
        this.reCaptchaInvalidate();
        return;
      }
      this.inProcess = true;
      authService
        .register(this.user)
        .then(() => {
          this.$refs.dialog.show({
            title: this.$t('register.popup_ok_title'),
            message: this.$t('register.successfullyMessage').split("#"),
            icon: 'thin-check',
          }).then(() => {
            this.$router.push('/');
          });
        })
        .catch((error) => {
          this.inProcess = false;
          this.reCaptchaInvalidate();
          this.errorMessage = ErrorHelper.getErrorMessage(error);
        });
    },
    linesFrom(value, splitter) {
      return ErrorHelper.getLinesFrom(value, splitter);
    },
    reCaptchaVerified(token) {
      this.user.reCaptchaToken = token;
    },
    reCaptchaInvalidate() {
      this.$refs.vueRecaptcha.reset();
      this.user.reCaptchaToken = '';
    },
    reCaptchaExpired() {
      this.reCaptchaInvalidate();
    },
    reCaptchaFailed() {
      this.reCaptchaInvalidate();
    },
  },
};
</script>
